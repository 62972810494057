<template>
  <div class="moment-popup" ref="popup">
    <img :src="closePopup" class="closePopup" alt="closePopup" @click="close" />
    <div class="left">
      <div
        class="image"
        :style="{ 'background-image': 'url(' + post.media.url + ')' }"
      ></div>
      <div class="slide" v-if="posts && posts.length > 0">
        <carousel :items="5" :margin="15">
          <div
            class="images-list"
            :style="{ 'background-image': 'url(' + item.media.url + ')' }"
            v-for="(item, index) in posts"
            :key="index"
            @click="viewImage(item)"
          ></div>
        </carousel>
      </div>
    </div>
    <div class="right">
      <div class="post-detail">
        <div class="title">
          <div
            class="profile"
            :style="{ 'background-image': 'url(' + post.author.picture + ')' }"
          ></div>
          <div class="profile-name">{{ post.author.fullName }}</div>
        </div>
        <VuePerfectScrollbar class="scroll-area">
          <div class="comment">{{ post.content }}</div>
          <div class="post-meta">
            <div class="platform">
              <span class="meta-label">Platform : :</span>
              <span class="meta-value">{{ post.network }}</span>
            </div>
            <div
              class="connected-expeirences"
              v-if="connectedExperiences && connectedExperiences.length > 0"
            >
              <div class="meta-label">Connected Experiences : :</div>
              <div class="connected-experiences-list">
                <router-link
                  v-for="exp in connectedExperiences"
                  :key="exp.id"
                  :to="{ name: 'map', query: { exp: exp.name } }"
                  class="experience-item"
                  target="_blank"
                  >{{ exp.title }}</router-link
                >
              </div>
            </div>
          </div>
        </VuePerfectScrollbar>
      </div>
      <div class="more-experiences">
        <router-link :to="{ name: 'map' }" class="btn find-more-button"
          >Find more experiences</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/router";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import carousel from "vue-owl-carousel";
import axios from "axios";
import closePopup from "../assets/img/themes/close-popup.png";

export default {
  router, // Important, if not. It won't get to work!
  name: "MomentPopup",
  components: {
    carousel,
    VuePerfectScrollbar,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      posts: [],
      closePopup,
      connectedExperiences: [],
    };
  },
  mounted() {
    if (this.post) {
      this.load();
      this.getConnectedExperiences();
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    load() {
      let url = `${process.env.VUE_APP_MOMENTS_URI}/campaigns/${process.env.VUE_APP_IMAGE_BOARD_ID}/posts?page=1&per_page=5&relate_id=${this.post.id}`;
      axios
        .get(url)
        .then((res) => {
          this.posts = res.data.posts;
        })
        .catch(console.error);
    },
    getConnectedExperiences() {
      if (!this.post || !this.post.content) return;

      const regex = /(#\w+|@\w+)/gim;
      const sources = this.post.content.match(regex).join(",");

      const url = process.env.VUE_APP_API_URI;

      const postData = {
        query: `query experiencesBySources($sources: String!) { 
          experiencesBySources(sources: $sources) { 
              id name title type hasLogo level image primaryColor
            }
          }`,
        variables: {
          sources,
        },
      };
      axios
        .post(
          url,
          postData,
          {
            headers: {
              "X-API-Key": process.env.VUE_APP_API_KEY,
            },
          }
        )
        .then((res) => {
          this.connectedExperiences = res.data.data.experiencesBySources;
        })
        .catch(console.error);
    },
    viewImage(item) {
      // console.log(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.moment-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55.98vw;
  height: 90vh;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #212121;
  z-index: 1040;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  display: flex;
  padding: 1.56vw 2.08vw;

  .left {
    width: 69.22%;
    padding-right: 2.08vw;
    .image {
      height: 76.21%;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .slide {
      width: 100%;
      height: 23.79%;
      padding-top: 1.35vw;

      .images-list {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        margin-right: 5px;
        background-color: #000;
        background-size: cover;
        display: inline-block;
      }
    }
  }

  .right {
    width: 30.36%;
    height: 100%;
    .post-detail {
      height: 76%;
    }
    .title {
      display: flex;
      align-items: center;
      .profile {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .profile-name {
        padding-left: 20px;
      }
    }

    .comment {
      font-size: 10px;
      padding: 1.5625vw 0;
      // background-color: #e13036;
    }

    .scroll-area {
      position: relative;
      margin: auto;
      height: 85%;
    }

    .post-meta {
      font-size: 10px;
      font-weight: bold;
    }

    .platform {
      .meta-value {
        color: #e13036;
        text-transform: capitalize;
      }
    }

    .connected-experiences-list {
      .experience-item {
        background-color: #e13036;
        color: #fff;
        border-radius: 5px;
        padding: 5px 10px;
        text-align: center;
        display: inline-block;
        text-decoration: none;
        margin-top: 10px;
        margin-right: 10px;
      }
    }
  }

  .more-experiences {
    height: 24%;
    position: relative;

    .find-more-button {
      border-color: #e13036;
      color: #e13036;
      font-size: 15px;
      padding: 10px 20px;
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;

      &:hover {
        background-color: #e13036;
        color: #fff;
      }
    }
  }

  .closePopup {
    cursor: pointer;
    position: absolute;
    right: -20px;
    top: -20px;
  }
}
</style>
