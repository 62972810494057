<template>
  <div class="ew-images-board" ref="container">
    <div
      class="ew-grid-wrapper"
      v-if="grid"
      :style="{ gridTemplateColumns: columnstyle, gridGap: `${gutter}px` }"
    >
      <div
        class="ew-post"
        v-for="(post, index) in posts"
        :key="index"
        :style="{ backgroundImage: `url(${post.thumbnail.url})` }"
        @click="() => onClickPost(post)"
      >
        <div class="ew-post-inner">
          <div class="ew-post-content">
            <i :class="`fab fa-${post.network}`"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="ew-justified-wrapper" v-if="!grid && layout && layout.boxes">
      <div :style="{ height: `${layout ? layout.containerHeight : 0}px` }">
        <div
          class="ew-post"
          v-for="(box, index) in layout.boxes"
          :key="index"
          :style="{
            backgroundImage: `url(${posts[index].thumbnail.url})`,
            width: box.width + 'px',
            height: box.height + 'px',
            transform: `translate(${box.left}px, ${box.top}px)`,
          }"
          @click="() => onClickPost(posts[index])"
        >
          <div class="ew-post-inner">
            <div class="ew-post-content">
              <i :class="`fab fa-${posts[index].network}`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import MomentPopup from "@/components/MomentPopup";
import axios from "axios";
import * as justified from "justified-layout";

export default {
  name: "ew-images-board",
  components: {
    MomentPopup,
  },
  props: {
    perPage: {
      type: Number,
      default: 15,
    },
    grid: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Number,
      default: 3,
    },
    rows: {
      type: Number,
    },
    gutter: {
      type: Number,
      default: 5,
    },
    showWidows: {
      type: Boolean,
      default: false,
    },
    loadMore: {
      type: Boolean,
      default: false,
    },
    rowHeight: {
      type: Number,
      default: 0,
    },
    tag: {
      type: String,
    },
    bounds: {
      type: Object,
    },
  },
  data: function () {
    return {
      loading: false,
      posts: [],
      final: true,
      next: null,
      layout: null,
      currentPost: null,
      containerHeight: 0,
      popup: null,
    };
  },
  computed: {
    columnstyle() {
      return Array.from({ length: this.columns })
        .map(() => "1fr")
        .join(" ");
    },
  },
  mounted() {
    this.load();
    this.calculateSize();
  },
  created() {
    if (this.loadMore) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  destroyed() {
    if (this.loadMore) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    load() {
      this.loading = true;

      const postData = {
        query: `
    query moments($limit: Int, $next: String, $travelerTypes: [ID]) {
      moments(limit: $limit, next: $next, travelerTypes: $travelerTypes) {
        hasNext
        next
        results {
          id
          content
          thumbnail {
            url
            width
            height
          }
          media {
            url
            width
            height
          }
          author {
            id
            name
            fullName
            link
            picture
          }
          network
        }
      }
    }
    `,
        variables: {
          limit: this.perPage,
          next: this.next,
          travelerTypes: this.tag ? [this.tag] : null,
        },
      };

      const url = process.env.VUE_APP_API_URI;
      axios
        .post(url, postData, {
          headers: {
            "X-API-Key": process.env.VUE_APP_API_KEY,
          },
        })
        .then((res) => {
          this.loading = false;

          const { moments } = res.data.data;
          const { results, next, hasNext } = moments;
          this.posts = this.posts.concat(results);
          this.final = !hasNext;
          this.next = next;
          this.layout = this.getLayout();
        })
        .catch(console.error);

      // let url = `${process.env.VUE_APP_MOMENTS_URI}/platforms/${
      //   process.env.VUE_APP_PLATFORM_ID
      // }/posts?page=${this.page}&per_page=${this.perPage}`;

      // // TODO: Apply tag
      // if (this.tag) {
      //   const theme = this.$store.state.ref.travelThemes[this.tag];
      //   if (theme) {
      //     url += "&tag_id=" + theme.id;
      //   }
      // }

      // if (this.bounds && this.bounds.southWest && this.bounds.northEast) {
      //   const { southWest, northEast } = this.bounds;
      //   url += `&bounds=${southWest.lat},${southWest.lng},${northEast.lat},${
      //     northEast.lng
      //   }`;
      // }

      // axios
      //   .get(url)
      //   .then(res => {
      //     this.loading = false;
      //     this.posts = this.posts.concat(res.data.posts);
      //     this.final = res.data.final;
      //     this.layout = this.getLayout();
      //   })
      //   .catch(console.error);
    },
    clear() {
      this.posts = [];
      this.final = true;
      this.page = 1;
    },
    goNext() {
      this.page++;
      this.load();
    },
    getLayout() {
      let ratio = [];

      if (this.grid) {
        ratio = this.posts.map(() => 1);
      } else {
        ratio = this.posts.map((post) => ({
          width: post.media ? post.media.width : 1,
          height: post.media ? post.media.height : 1,
        }));
      }

      let config = {
        containerWidth: this.$refs.container.clientWidth,
        containerPadding: 0,
        boxSpacing: this.gutter,
        showWidows: this.showWidows,
      };

      if (this.rowHeight > 0) {
        config.targetRowHeight = this.rowHeight;
      }

      console.log(ratio);

      return justified(ratio, config);
    },
    handleScroll(ev) {
      if (this.loading) return;

      const container = this.$refs.container;
      if (!container) return;

      if (this.final) {
        window.removeEventListener("scroll", this.handleScroll);
      }

      const top = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const bottomWindow = top + windowHeight;
      const bottomElement = container.clientTop + container.clientHeight;
      if (bottomWindow >= bottomElement) {
        this.goNext();
      }
    },
    onClickPost(post) {
      if (this.popup) {
        this.onClosePopup();
      }

      // Open popup.
      const PopupClass = Vue.extend(MomentPopup);
      this.popup = new PopupClass({
        propsData: { post },
      });
      this.popup.close = this.onClosePopup;
      this.popup.$mount();
      document.querySelector("#app").firstChild.appendChild(this.popup.$el);
    },
    onClosePopup() {
      if (this.popup) {
        document.querySelector("#app").firstChild.removeChild(this.popup.$el);
        this.popup.$destroy();
        this.popup = null;
      }
    },
    calculateSize() {
      if (this.grid && this.rows > 0 && this.columns > 0) {
        const container = this.$refs.container;
        const parentNode = container.parentNode;
        const height = parentNode.clientHeight;
        const width =
          (height / this.rows) * this.columns +
          (this.columns - 1) * this.gutter;
        const ret = {
          width,
          height,
        };

        this.$emit("calculatesize", ret);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ew-images-board {
  width: 100%;
  overflow: hidden;

  .ew-grid-wrapper {
    display: grid;
    grid-auto-rows: 1fr;

    .ew-post {
      position: relative;
      &::before {
        content: "";
        width: 0;
        padding-bottom: 100%;
        display: block;
      }
    }
  }

  .ew-justified-wrapper {
    position: relative;
    width: 100%;

    .ew-post {
      position: absolute;
    }
  }

  .ew-post {
    display: block;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .ew-post-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .ew-post-content {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    padding: 12px;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.7)
    );
    & > i {
      color: #fff;
      font-size: 20px;
    }
  }
}
</style>
